@import '~antd/dist/antd.css';



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #f59723;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

/*Header style*/
.logo {
  /*background-color: rgb(2 21 41 / 28%) !important;*/
  border-bottom: 2px solid #ffffff54;
  margin: auto;
  display: block;
}

/*Drawer Style*/
.sider-logo {
  max-width: 100%;
  min-width: 50%;
  padding: 20px;
}


/*auth base*/

.authMainLayout {
  min-height: 100vh !important;
  width: 100%;
  background: linear-gradient(180deg, rgba(73, 166, 130, 0.8757878151260504) 0%, rgba(6, 55, 123, 0.8897934173669468) 66%) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 15vh;
}

.authContainer {
  background-color: white;
  /*z-index: 99999;*/
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  border-radius: 8px;
  width: 33%;
  padding: 30px;
}

.authLogoContainer {
  text-align: center;
  /*padding: 15px;*/
}

.authLogo {
  max-width: 90%;
  min-width: 15%;
  max-height: 200px;
  margin: auto;
}

.btnFloatRight {
  float: right;
}

.auth-type-title {
  text-align: center;
  margin: 10px;
}

/*mobile View*/
@media (max-width: 575px) {
  .authMainLayout {
    /*background-color: white !important;*/
    padding: 15px;
  }

  .authContainer {
    width: 100%;
    margin-top: 0px;
    box-shadow: none !important;
    position: absolute;
    bottom: 0;
  }

  .btnFloatRight {
    float: left;
  }
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #5a5a5a;
}
.theme-color {
  backgroundColor:#06377b;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}
.ant-menu-item-selected {
  background-color: #3faecc !important;
}
.ant-menu-item-hover {
  background-color: #3faecc !important;
}
.ant-menu{
  background: red;
}

.sidebar{
 color: red;
  background: rebeccapurple;
}

.logo {
  width: 100%;
  max-width: 70px;
  max-height: 34px;
}
.ant-table table { font-size: 13px;
  width: 100%;
}



/*.rowClassName1 {*/
/*  height: 3px;*/
/*  background-color: cadetblue;*/
/*  border-radius: 10px;*/
/*  border-color: chartreuse;*/
/*  border-width: 3px;*/
/*  border-style: solid;*/
/*}*/

@font-face {
  font-family: 'Open Sans Reguler';
  src: local('Open Sans Reguler'), url('./app/assets/fonts/open-sans-v29-latin-regular.woff') format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.apexcharts-menu-item.exportCSV { display: none; }